// Support component names relative to this directory:

require('../../assets/stylesheets/application.css')

import jquery from "jquery"
window.jQuery = jquery
window.$ = jquery

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(function(){
    setTimeout(function(){
    $('.callout').fadeOut();
    }, 5000);
  })